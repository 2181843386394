import { Box } from '@material-ui/core'
import React, { PropsWithChildren, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { FlexRow } from 'sdk-xy-react'

import LifeHashAppBar from '../AppBar/index'
import { CoverProgress } from '../Components'
import Footer from '../Components/Footer'
import FirebaseContext from '../Contexts/FirebaseContext'

interface ConnectionPageProps {
  requireAuth?: boolean
}

const OAuthPage: React.FC<PropsWithChildren<ConnectionPageProps>> = (props) => {
  const { requireAuth = false, children } = props
  const { signedIn } = useContext(FirebaseContext)

  if (signedIn === undefined) {
    return <CoverProgress />
  } else if (!signedIn && requireAuth) {
    return <Redirect to="/account/signin" />
  }

  return (
    <FlexRow>
      <Box display="flex" flexDirection="column" maxWidth="100vw" minHeight="100vh">
        <LifeHashAppBar />
        <Box display="flex" flexGrow={1}>
          {children}
        </Box>
        <Footer />
      </Box>
    </FlexRow>
  )
}

export default OAuthPage
