import React from 'react'
import { LifehashError, TelephoneEx } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import ApiContext from '../Contexts/ApiContext'

const useTelephones = () => {
  const [telephones, setTelephones] = React.useState<TelephoneEx[]>()
  const [error, setError] = React.useState<LifehashError>()

  const apiContext = React.useContext(ApiContext)
  const api = apiContext.api
  const apiError = apiContext.error

  React.useEffect(() => {
    setError(undefined)
    if (apiError) {
      setError(apiError)
      setTelephones(undefined)
    }
  }, [apiError])

  useAsyncEffect(
    async (isMounted) => {
      setTelephones(undefined)
      setError(undefined)
      if (api) {
        try {
          const telephones = await api.getTelephones()
          if (isMounted()) {
            setTelephones(telephones)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [api]
  )

  return { error, telephones }
}

export default useTelephones
