import { Typography } from '@material-ui/core'
import React from 'react'
import { FlexCol } from 'sdk-xy-react'

import BasePage from '../BasePage'

const Home: React.FC = () => {
  return (
    <BasePage>
      <FlexCol justifyContent="flex-start" width="100vw">
        <Typography variant="h1">Lifehash - Your Data, Your Rules</Typography>
      </FlexCol>
    </BasePage>
  )
}

export default Home
