import React from 'react'

import EmailsContext from '../Contexts/EmailsContext'
import { useEmails } from '../Hooks'

const EmailsLoader: React.FC = (props) => {
  const { emails, error } = useEmails()

  return <EmailsContext.Provider value={{ emails, error }} {...props} />
}

export default EmailsLoader
