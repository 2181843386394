import { BoxProps } from '@material-ui/core'
import React from 'react'
import { LifehashConnectionProviders } from 'sdk-lifehash-js'
import { FlexRow } from 'sdk-xy-react'

import ProviderIcon from '../../../Components/ProviderIcon'

interface Props extends BoxProps {
  providers?: LifehashConnectionProviders[]
  size?: number
}

const ScoreViewer: React.FC<Props> = (props) => {
  const { providers, size = 48, ...boxProps } = props
  return (
    <FlexRow {...boxProps}>
      {providers?.map((provider, index) => {
        return <ProviderIcon key={`${provider}|${index}`} provider={provider} size={size} marginX={0.5} />
      })}
    </FlexRow>
  )
}

export default ScoreViewer
