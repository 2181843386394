import { BoxProps, IconButton } from '@material-ui/core'
import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import { CoverProgress } from '../Components'
import ApiContext from '../Contexts/ApiContext'
import TelephonesContext from '../Contexts/TelephonesContext'
import ListItem from './Details/ListItem'

const TelephoneList: React.FC<BoxProps> = (props) => {
  const { telephones, error } = React.useContext(TelephonesContext)
  const { refreshAll } = React.useContext(ApiContext)
  const onErrorRetry = () => {
    refreshAll?.()
  }

  return (
    <FlexGrowCol {...props} alignItems="stretch" justifyContent="flex-start" position="relative" marginY={1}>
      <FlexRow margin={1}>
        <IconButton>
          <AiOutlinePlusCircle size={40} />
        </IconButton>
      </FlexRow>
      {telephones?.map((telephone) => {
        return (
          <FlexRow marginY={1} key={telephone.ex_id}>
            <ListItem telephone={telephone} providers={telephone.ex_providers} />
          </FlexRow>
        )
      })}
      <CoverProgress paper={false} open={!telephones} onRetry={onErrorRetry} errors={error ? [error] : undefined} />
    </FlexGrowCol>
  )
}

export default TelephoneList
