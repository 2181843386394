import React from 'react'
import { ApiStage, LifehashApiWrapper, LifehashConnectionProviders, LifehashError } from 'sdk-lifehash-js'

interface ApiContextProps {
  apiStage?: ApiStage
  apiDomain?: string
  api?: LifehashApiWrapper
  removeConnection?: (provider: LifehashConnectionProviders, providerId: string) => Promise<void>
  refreshConnection?: (provider: LifehashConnectionProviders, providerId: string) => Promise<void>
  refreshAll?: () => void
  error?: LifehashError
}

const ApiContext = React.createContext<ApiContextProps>({})
export default ApiContext
