import React from 'react'

import TelephonesContext from '../Contexts/TelephonesContext'
import { useTelephones } from '../Hooks'

const TelephonesLoader: React.FC = (props) => {
  const { telephones, error } = useTelephones()

  return <TelephonesContext.Provider value={{ error, telephones }} {...props} />
}

export default TelephonesLoader
