import { BoxProps } from '@material-ui/core'
import React from 'react'
import JsonView from 'react-json-view'
import { FlexGrowRow } from 'sdk-xy-react'

interface Props extends BoxProps {
  open?: boolean
  json?: any
}

const DetailsJsonViewer: React.FC<Props> = (props) => {
  const { json, open, ...boxProps } = props
  return open ? (
    <FlexGrowRow {...boxProps}>
      <JsonView collapseStringsAfterLength={96} src={json} style={{ overflow: 'hidden', width: '100%' }} />
    </FlexGrowRow>
  ) : null
}

export default DetailsJsonViewer
