import { BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { GoUnverified, GoVerified } from 'react-icons/go'
import { FlexRow } from 'sdk-xy-react'

interface Props extends BoxProps {
  email?: string
  verified?: boolean
  emailPublic?: boolean
}

const EmailViewer: React.FC<Props> = (props) => {
  const { emailPublic, email, verified, ...boxProps } = props
  return (
    <FlexRow {...boxProps}>
      <Typography>{email ?? '--'}</Typography>
      {verified !== undefined ? (
        <FlexRow marginLeft={0.5}>{verified ? <GoVerified /> : <GoUnverified />}</FlexRow>
      ) : null}
      {emailPublic !== undefined ? (
        <FlexRow marginLeft={0.5}>{emailPublic ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}</FlexRow>
      ) : null}
    </FlexRow>
  )
}

export default EmailViewer
