import { Button } from '@material-ui/core'
import React from 'react'
import { FlexGrowCol, FlexGrowPaper, FlexRow } from 'sdk-xy-react'

import { ScoreViewer } from '../../Components'
import AddressInfo from '../DeviceInfo'
import { DetailsJsonViewer, InfoViewer } from './Viewer'

interface Props {
  info: AddressInfo
}

const ListItem: React.FC<Props> = (props) => {
  const { info } = props
  const [jsonOpen, setJsonOpen] = React.useState(false)

  const onRefresh = () => {
    alert(`Done: ${info.id}`)
  }

  const onJson = () => {
    setJsonOpen(!jsonOpen)
  }

  return (
    <FlexGrowPaper padding={1}>
      <FlexGrowCol alignItems="stretch">
        <FlexRow>
          <InfoViewer device={info.device} />
          <FlexRow marginX={0.5}>
            <Button variant="outlined" onClick={onJson}>
              Json
            </Button>
          </FlexRow>
          <FlexRow marginX={0.5}>
            <Button variant="outlined" onClick={onRefresh}>
              Refresh
            </Button>
          </FlexRow>
          <ScoreViewer value={10} margin={0.5} />
        </FlexRow>
        <DetailsJsonViewer json={info} open={jsonOpen} margin={1} />
      </FlexGrowCol>
    </FlexGrowPaper>
  )
}

export default ListItem
