import { Container } from '@material-ui/core'
import React from 'react'

import BasePage from '../BasePage'
import EmailsLoader from '../ContextLoaders/EmailsLoader'
import EmailList from './EmailList'

const ListPage: React.FC = () => {
  return (
    <BasePage requireAuth={true}>
      <EmailsLoader>
        <Container>
          <EmailList />
        </Container>
      </EmailsLoader>
    </BasePage>
  )
}

export default ListPage
