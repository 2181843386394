import { Box, Container, Paper, Switch, Typography } from '@material-ui/core'
import React, { useContext } from 'react'

import BasePage from '../BasePage'
import AppSettingsContext from '../Contexts/AppSettingsContext'

const SettingsPage: React.FC = () => {
  const { darkMode, enableDarkMode, denseMode, enableDenseMode } = useContext(AppSettingsContext)

  const handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enableDarkMode?.(event.target.checked)
  }

  const handleDenseModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enableDenseMode?.(event.target.checked)
  }

  return (
    <BasePage requireAuth={true}>
      <Container>
        <Box display="flex" flexDirection="column" padding={2}>
          <Box padding={1}>
            <Paper variant="outlined">
              <Box alignItems="center" display="flex" justifyContent="space-between" margin={2}>
                <Typography variant="caption">Dark Mode</Typography>
                <Switch checked={darkMode} color="primary" onChange={handleDarkModeChange} />
              </Box>
            </Paper>
          </Box>
          <Box padding={1}>
            <Paper variant="outlined">
              <Box alignItems="center" display="flex" justifyContent="space-between" margin={2}>
                <Typography variant="caption">Dense Mode</Typography>
                <Switch checked={denseMode} color="primary" onChange={handleDenseModeChange} />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Container>
    </BasePage>
  )
}

export default SettingsPage
