import { BoxProps, Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { FlexRow } from 'sdk-xy-react'

interface Props extends BoxProps {
  name: string
}

const ScopeViewer: React.FC<Props> = (props) => {
  const { name, ...boxProps } = props
  return (
    <FlexRow {...boxProps}>
      <FormControlLabel control={<Checkbox />} label={name} />
    </FlexRow>
  )
}

export default ScopeViewer
