import { Container } from '@material-ui/core'
import React from 'react'

import BasePage from '../BasePage'
import AddressList from './AddressList'

const ListPage: React.FC = () => {
  return (
    <BasePage requireAuth={true}>
      <Container>
        <AddressList />
      </Container>
    </BasePage>
  )
}

export default ListPage
