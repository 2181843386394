import React from 'react'
import { LifehashError, PersonEx } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import useConnections from './useConnections'

const useCompoundPerson = () => {
  const [person, setPerson] = React.useState<PersonEx>()

  const connectionsHook = useConnections()
  const connections = connectionsHook.connections
  const connectionsError = connectionsHook.error

  const [error, setError] = React.useState<LifehashError>()

  React.useEffect(() => {
    setError(undefined)
    if (connectionsError) {
      setError(connectionsError)
      setPerson(undefined)
    }
  }, [connectionsError])

  useAsyncEffect(
    async (isMounted) => {
      setError(undefined)
      setPerson(undefined)
      if (connections) {
        try {
          let person: PersonEx | undefined
          const promises: Promise<PersonEx | undefined>[] = []
          for (const connection of connections ?? []) {
            promises.push(connection.userInfo().get())
          }
          const userInfoList = await Promise.all(promises)
          for (const userInfo of userInfoList) {
            if (userInfo) {
              person = { ...userInfo, ...person }
            }
          }
          if (isMounted()) {
            setPerson(person)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [connections]
  )

  return { error, person }
}

export default useCompoundPerson
