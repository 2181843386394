import { useTheme } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import { FlexGrowRow } from 'sdk-xy-react'

interface Props {
  open?: boolean
}

const CoverError: React.FC<PropsWithChildren<Props>> = (props) => {
  const { open, children } = props
  const theme = useTheme()
  if (open) {
    return (
      <FlexGrowRow position="absolute" top={0} bottom={0} right={0} left={0} bgcolor={theme.palette.background.paper}>
        {children}
      </FlexGrowRow>
    )
  }
  return null
}

export default CoverError
