import { Button } from '@material-ui/core'
import React from 'react'
import { FlexGrowCol, FlexGrowPaper, FlexRow } from 'sdk-xy-react'

import EmailInfo from '../PersonInfo'
import { DetailsJsonViewer, InfoViewer } from './Viewer'

interface Props {
  info: EmailInfo
}

const ListItem: React.FC<Props> = (props) => {
  const { info } = props
  const [jsonOpen, setJsonOpen] = React.useState(false)

  const onRefresh = () => {
    alert(`Done: ${info.id}`)
  }

  const onJson = () => {
    setJsonOpen(!jsonOpen)
  }

  return (
    <FlexGrowPaper padding={1} variant="outlined">
      <FlexGrowCol alignItems="stretch">
        <FlexRow>
          <InfoViewer info={info} />
          <FlexRow marginX={0.5}>
            <Button variant="outlined" onClick={onJson}>
              Json
            </Button>
          </FlexRow>
          <FlexRow marginX={0.5}>
            <Button variant="outlined" onClick={onRefresh}>
              Refresh
            </Button>
          </FlexRow>
        </FlexRow>
        <DetailsJsonViewer json={info} open={jsonOpen} margin={1} />
      </FlexGrowCol>
    </FlexGrowPaper>
  )
}

export default ListItem
