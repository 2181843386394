import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ListPage from './ListPage'

const Devices: React.FC = () => {
  return (
    <Switch>
      <Route component={ListPage} exact path="/devices" />
      <Route>
        <Redirect to={'/'} />
      </Route>
    </Switch>
  )
}

export default Devices
