import { BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { LifehashConnectionProviders } from 'sdk-lifehash-js'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import { CoverProgress } from '../../../Components'
import CoverError from '../CoverError'
import Providers from './Providers'
import TelephoneViewer from './Telephone'

interface Props extends BoxProps {
  providers?: LifehashConnectionProviders[]
  telephonePublic?: boolean
  telephone?: string
  error?: Error
}

const InfoViewer: React.FC<Props> = (props) => {
  const { telephone, telephonePublic, providers, error, ...boxProps } = props

  return (
    <FlexGrowCol {...boxProps} alignItems="stretch" position="relative">
      <FlexRow justifyContent="flex-start">
        <TelephoneViewer marginX={0.5} number={telephone} numberPublic={telephonePublic} />
        <Providers providers={providers} />
      </FlexRow>
      <CoverProgress open={!telephone && !error} />
      <CoverError open={!!error}>
        <FlexRow marginX={1} flexGrow={1} justifyContent="flex-start">
          <Typography>{error?.toString()}</Typography>
        </FlexRow>
      </CoverError>
    </FlexGrowCol>
  )
}

export default InfoViewer
