import { AppBar, Box, Button, IconButton, Toolbar } from '@material-ui/core'
import React, { useContext } from 'react'
import {
  AiOutlineApi,
  AiOutlineLink,
  AiOutlineLogin,
  AiOutlineLogout,
  AiOutlineNumber,
  AiOutlineSetting,
  AiOutlineTeam,
  AiOutlineUser,
} from 'react-icons/ai'
import { FiAtSign } from 'react-icons/fi'
import { Link as RouterLink } from 'react-router-dom'

import AppSettingsContext from '../Contexts/AppSettingsContext'
import FirebaseContext from '../Contexts/FirebaseContext'
import logo from './img/logo.png'

const PortalToolBar: React.FC = () => {
  const { denseMode } = useContext(AppSettingsContext)
  const { firebaseWrapper } = useContext(FirebaseContext)
  const currentUser = firebaseWrapper?.currentUser()
  return (
    <Toolbar variant={denseMode ? 'dense' : 'regular'}>
      <Box display="flex" flexDirection="row" width="100vw">
        <Button component={RouterLink} to="/">
          <Box
            height={32}
            margin={1}
            style={{ backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
            width={32}
          />
        </Button>
        <Box display="flex" flexGrow={1} />
        <Box alignItems="right" display="flex" flexDirection="row">
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/" title="Profile">
              <AiOutlineUser size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/connections" title="Connections">
              <AiOutlineApi size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/emails" title="Emails">
              <FiAtSign size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/telephones" title="Phones">
              <AiOutlineNumber size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/people" title="People">
              <AiOutlineTeam size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/blockchain" title="Blockchain">
              <AiOutlineLink size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/account/settings" title="Settings">
              <AiOutlineSetting size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/account/signout" title="Sign Out">
              <AiOutlineLogout size={24} color="white" />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'none' : 'flex'} padding={1}>
            <IconButton component={RouterLink} to="/account/signin" title="Sign In">
              <AiOutlineLogin size={24} color="white" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Toolbar>
  )
}

const PortalAppBar: React.FC = () => {
  return (
    <Box width="100vw">
      <AppBar position="fixed">
        <PortalToolBar />
      </AppBar>
      <PortalToolBar />
    </Box>
  )
}

export default PortalAppBar
