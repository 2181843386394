import { BoxProps, Button } from '@material-ui/core'
import React from 'react'
import {
  AppleOauth2Wrapper,
  FacebookOauth2Wrapper,
  FacebookScopes,
  FacebookUserScopes,
  GithubOauth2Wrapper,
  GoogleOauth2Wrapper,
  LifehashConnectionProviders,
  LinkedinOauth2Wrapper,
  LinkedinProfileScopes,
  LinkedinScopes,
  TwitterOauthWrapper,
  YahooOauth2Wrapper,
} from 'sdk-lifehash-js'
import { assertEx, FlexRow } from 'sdk-xy-react'

import ApiContext from '../Contexts/ApiContext'
import FirebaseContext from '../Contexts/FirebaseContext'
import Providers from './Providers'

enum GoogleApiScopes {
  UserEmailsRead = 'https://www.googleapis.com/auth/user.emails.read',
  UserInfoEmail = 'https://www.googleapis.com/auth/userinfo.email',
  UserInfoProfile = 'https://www.googleapis.com/auth/userinfo.profile',
  ProfileEmailsRead = 'https://www.googleapis.com/auth/profile.emails.read',
  Contacts = 'https://www.googleapis.com/auth/contacts',
  ContactsReadOnly = 'https://www.googleapis.com/auth/contacts.readonly',
  DirectoryReadOnly = 'https://www.googleapis.com/auth/directory.readonly',
  ProfileAgeRangeRead = 'https://www.googleapis.com/auth/profile.agerange.read',
  ProfileLangaugeRead = 'https://www.googleapis.com/auth/profile.language.read',
  UserAddressesRead = 'https://www.googleapis.com/auth/user.addresses.read',
  UserBirthdayRead = 'https://www.googleapis.com/auth/user.birthday.read',
  UserGenderRead = 'https://www.googleapis.com/auth/user.gender.read',
  UserOrganizationRead = 'https://www.googleapis.com/auth/user.organization.read',
  UserPhoneNumbersRaad = 'https://www.googleapis.com/auth/user.phonenumbers.read',
}

enum GooglePhotosScopes {
  ReadOnly = 'https://www.googleapis.com/auth/photoslibrary.readonly',
  WiteOnly = 'https://www.googleapis.com/auth/photoslibrary.appendonly',
  AppCreatedReadOnly = 'https://www.googleapis.com/auth/photoslibrary.readonly.appcreateddata',
  EditOnly = 'https://www.googleapis.com/auth/photoslibrary.edit.appcreateddata',
  ReadAppendOnly = 'https://www.googleapis.com/auth/photoslibrary',
  Sharing = 'https://www.googleapis.com/auth/photoslibrary.sharing',
}

enum GoogleDriveScopes {
  AppDataFolder = 'https://www.googleapis.com/auth/drive.appdata',
  File = 'https://www.googleapis.com/auth/drive.file',
  Install = 'https://www.googleapis.com/auth/drive.install',
  AppsReadOnly = 'https://www.googleapis.com/auth/drive.apps.readonly',
  MetaData = 'https://www.googleapis.com/auth/drive.metadata',
  Drive = 'https://www.googleapis.com/auth/drive',
  DriveActivity = 'https://www.googleapis.com/auth/drive.activity',
  DriveActivityReadOnly = 'https://www.googleapis.com/auth/drive.activity.readonly',
  DriveReadOnly = 'https://www.googleapis.com/auth/drive.readonly',
  DriveMetaDataReadOnly = 'https://www.googleapis.com/auth/drive.metadata.readonly',
  Scripts = 'https://www.googleapis.com/auth/drive.scripts',
}

const AddConnectionButtons: React.FC<BoxProps> = (props) => {
  const { user } = React.useContext(FirebaseContext)
  const { api, apiStage, apiDomain } = React.useContext(ApiContext)

  const lifehash = assertEx(user?.uid, 'Missing Lifehash')

  const addGoogleConnection = async () => {
    const scopes = [
      'profile',
      'email',
      'openid',
      GoogleApiScopes.UserEmailsRead,
      GoogleApiScopes.UserInfoEmail,
      GoogleApiScopes.UserInfoProfile,
      GoogleApiScopes.ProfileEmailsRead,
      GoogleApiScopes.UserPhoneNumbersRaad,
      GoogleApiScopes.UserGenderRead,
      GoogleApiScopes.ProfileAgeRangeRead,
      GoogleApiScopes.ContactsReadOnly,
      GoogleApiScopes.DirectoryReadOnly,
      GoogleApiScopes.ProfileLangaugeRead,
      GoogleApiScopes.UserAddressesRead,
      GoogleApiScopes.UserBirthdayRead,
      GoogleApiScopes.UserOrganizationRead,
      GooglePhotosScopes.ReadOnly,
      GooglePhotosScopes.EditOnly,
      GooglePhotosScopes.ReadAppendOnly,
      GooglePhotosScopes.Sharing,
      GoogleDriveScopes.MetaData,
      GoogleDriveScopes.Drive,
      GoogleDriveScopes.File,
      GoogleDriveScopes.AppDataFolder,
    ]
    const apiWrapper = new GoogleOauth2Wrapper(assertEx(apiStage))
    const uri = await apiWrapper.getUri(lifehash, assertEx(apiDomain), document.location.href, scopes)
    if (uri) {
      window.location.href = uri
    }
  }

  const addAppleConnection = () => {
    const scopes = ['name', 'email']
    const apiWrapper = new AppleOauth2Wrapper(assertEx(apiStage))
    const uri = apiWrapper.getUri(lifehash, assertEx(apiDomain), document.location.href, scopes)
    if (uri) {
      window.location.href = uri
    }
  }

  const addGithubConnection = () => {
    const githubApiWrapper = new GithubOauth2Wrapper(assertEx(apiStage))
    const uri = githubApiWrapper.getUri(lifehash, assertEx(apiDomain), document.location.href, ['user', 'repo'])
    if (uri) {
      window.location.href = uri
    }
  }

  const addYahooConnection = () => {
    const yahooApiWrapper = new YahooOauth2Wrapper(assertEx(apiStage))
    const uri = yahooApiWrapper.getUri(lifehash, assertEx(apiDomain), document.location.href, [
      'openid',
      'email',
      'profile',
      'openid2',
    ])
    if (uri) {
      window.location.href = uri
    }
  }

  const addTwitterConnection = async () => {
    const requestToken = await api?.fetchTwitterRequestToken(lifehash, document.location.href)
    console.log(`requestToken: ${JSON.stringify(requestToken)}`)
    if (requestToken?.oauth_token) {
      const oauth = new TwitterOauthWrapper(assertEx(apiStage))
      const uri = oauth.buildAuthorizeUrl(`${requestToken?.oauth_token}`)
      if (uri) {
        window.location.href = uri
      }
    }
  }

  const addLinkedinConnection = () => {
    const apiWrapper = new LinkedinOauth2Wrapper(assertEx(apiStage))
    const uri = apiWrapper.getUri(lifehash, assertEx(apiDomain), document.location.href, [
      LinkedinProfileScopes.Lite,
      LinkedinScopes.Email,
    ])
    if (uri) {
      window.location.href = uri
    }
  }

  const addFacebookConnection = () => {
    const facebookApiWrapper = new FacebookOauth2Wrapper(assertEx(apiStage))

    const scopes = [
      FacebookScopes.Email,
      FacebookUserScopes.AgeRange,
      FacebookUserScopes.Birthday,
      FacebookUserScopes.Friends,
      FacebookUserScopes.Gender,
      FacebookUserScopes.Hometown,
      FacebookUserScopes.Likes,
      FacebookUserScopes.Link,
      FacebookUserScopes.Location,
      FacebookUserScopes.Photos,
      FacebookUserScopes.Posts,
      FacebookUserScopes.Videos,
    ]

    const uri = facebookApiWrapper.getUri(lifehash, assertEx(apiDomain), document.location.href, scopes)
    if (uri) {
      window.location.href = uri
    }
  }

  const onConnect = async (provider: LifehashConnectionProviders) => {
    switch (provider) {
      case LifehashConnectionProviders.Google:
        return await addGoogleConnection()
      case LifehashConnectionProviders.Github:
        return addGithubConnection()
      case LifehashConnectionProviders.Facebook:
        return addFacebookConnection()
      case LifehashConnectionProviders.Linkedin:
        return addLinkedinConnection()
      case LifehashConnectionProviders.Twitter:
        return addTwitterConnection()
      case LifehashConnectionProviders.Apple:
        return addAppleConnection()
      case LifehashConnectionProviders.Yahoo:
        return addYahooConnection()
    }
  }
  return (
    <FlexRow flexWrap="wrap" {...props}>
      {Object.entries(Providers).map((entry, index) => {
        const provider = entry[0] as LifehashConnectionProviders
        const info = entry[1]
        return (
          <FlexRow margin={1} key={index}>
            <Button disabled={!info.enabled} onClick={() => onConnect(provider)} variant="outlined">
              + {info.icon({ size: 32 })}
            </Button>
          </FlexRow>
        )
      })}
    </FlexRow>
  )
}

export default AddConnectionButtons
