import { BoxProps, IconButton } from '@material-ui/core'
import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import { CoverProgress } from '../Components'
import ApiContext from '../Contexts/ApiContext'
import EmailsContext from '../Contexts/EmailsContext'
import ListItem from './Details/ListItem'

const EmailList: React.FC<BoxProps> = (props) => {
  const { emails, error } = React.useContext(EmailsContext)
  const { refreshAll } = React.useContext(ApiContext)
  const onErrorRetry = () => {
    refreshAll?.()
  }

  return (
    <FlexGrowCol {...props} position="relative" alignItems="stretch" justifyContent="flex-start" marginY={1}>
      <FlexRow margin={1}>
        <IconButton>
          <AiOutlinePlusCircle size={40} />
        </IconButton>
      </FlexRow>
      {emails?.map((email) => {
        return (
          <FlexRow marginY={1} key={email.ex_id}>
            <ListItem email={email} providers={email.ex_providers} />
          </FlexRow>
        )
      })}
      <CoverProgress paper={false} open={!emails} onRetry={onErrorRetry} errors={error ? [error] : undefined} />
    </FlexGrowCol>
  )
}

export default EmailList
