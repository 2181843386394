import React from 'react'
import { LifehashError, TelephoneEx } from 'sdk-lifehash-js'

interface TelephonesContextProps {
  telephones?: TelephoneEx[]
  error?: LifehashError
  refresh?: () => void
}

const TelephonesContext = React.createContext<TelephonesContextProps>({})
export default TelephonesContext
