import { BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import { CoverProgress } from '../../../Components'
import CoverError from '../CoverError'
import EmailViewer from './Email'

interface Props extends BoxProps {
  email?: string
  error?: Error
}

const InfoViewer: React.FC<Props> = (props) => {
  const { email, error, ...boxProps } = props

  return (
    <FlexGrowCol {...boxProps} alignItems="stretch" position="relative">
      <FlexRow justifyContent="flex-start">
        <EmailViewer marginX={0.5} email={email} />
      </FlexRow>
      <CoverProgress open={!email && !error} />
      <CoverError open={!!error}>
        <FlexRow marginX={1} flexGrow={1} justifyContent="flex-start">
          <Typography>{error?.toString()}</Typography>
        </FlexRow>
      </CoverError>
    </FlexGrowCol>
  )
}

export default InfoViewer
