import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Home from './Home'
import Privacy from './Privacy'
import Terms from './Terms'

const Brochure: React.FC = () => {
  return (
    <Switch>
      <Route component={Home} exact path="/" />
      <Route component={Terms} exact path="/terms" />
      <Route component={Privacy} exact path="/privacy" />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Brochure
