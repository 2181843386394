import { CardProps, useTheme } from '@material-ui/core'
import React from 'react'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import { CoverProgress, ScoreViewer } from '../../Components'
import ApiContext from '../../Contexts/ApiContext'
import useScore from '../../Hooks/useScore'
import DashCard from './DashCard'
import Delta from './Delta'

const dashboardUnitSize = 150

const AccountScoreDashCard: React.FC<CardProps> = (props) => {
  const theme = useTheme()
  const { score, error } = useScore()
  const { refreshAll } = React.useContext(ApiContext)

  const onRefresh = () => {
    refreshAll?.()
  }

  const onInfo = () => {
    refreshAll?.()
  }

  return (
    <DashCard {...props} onRefresh={onRefresh} onInfo={onInfo}>
      <FlexRow>
        <ScoreViewer value={score} size={dashboardUnitSize - theme.spacing() * 2} />
        <FlexGrowCol marginLeft={1} alignItems="stretch" justifyContent="flex-start" position="relative">
          <Delta margin={1} label="1 Day" change={-10} />
          <Delta margin={1} label="7 Day" change={+5} />
          <Delta margin={1} label="30 Day" change={+45} />
          <CoverProgress open={!score} errors={error ? [error] : undefined} onRetry={onRefresh} />
        </FlexGrowCol>
      </FlexRow>
    </DashCard>
  )
}

export default AccountScoreDashCard
