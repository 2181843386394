import React from 'react'
import { EmailEx, LifehashError } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import ApiContext from '../Contexts/ApiContext'

const useEmails = () => {
  const [emails, setEmails] = React.useState<EmailEx[]>()
  const [error, setError] = React.useState<LifehashError>()

  const apiContext = React.useContext(ApiContext)
  const api = apiContext.api
  const apiError = apiContext.error

  React.useEffect(() => {
    setError(undefined)
    if (apiError) {
      setError(apiError)
      setEmails(undefined)
    }
  }, [apiError])

  useAsyncEffect(
    async (isMounted) => {
      setError(undefined)
      setEmails(undefined)
      if (api) {
        try {
          const emails = await api.getEmails()
          if (isMounted()) {
            setEmails(emails)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [api]
  )

  return { emails, error }
}

export default useEmails
