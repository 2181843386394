import { Container } from '@material-ui/core'
import React from 'react'

import BasePage from '../BasePage'
import DeviceList from './DeviceList'

const ListPage: React.FC = () => {
  return (
    <BasePage requireAuth={true}>
      <Container>
        <DeviceList />
      </Container>
    </BasePage>
  )
}

export default ListPage
