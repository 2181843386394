import { Box, Button, TextField, Typography } from '@material-ui/core'
import React, { ChangeEvent, useContext, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import BasePage from '../BasePage'
import FirebaseContext from '../Contexts/FirebaseContext'

const CreatePage: React.FC = () => {
  const { firebaseWrapper } = useContext(FirebaseContext)
  const [email, setEmail] = useState('')
  const [inviteCode, setInviteCode] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()

  const onCreate = async () => {
    try {
      if (inviteCode === 'hashme') {
        await firebaseWrapper?.signUp(email, password)
        history.push('/')
      }
    } catch (ex) {
      alert(ex.message ?? ex)
    }
  }

  const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value)
  }

  const onInviteCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInviteCode(event.target.value)
  }

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  return (
    <BasePage>
      <Box alignItems="center" display="flex" flexDirection="column" flexGrow={1} justifyContent="center" width="100vw">
        <Typography variant="h1">Create a Lifehash account</Typography>
        <Box display="flex" flexDirection="column" width={300}>
          <TextField id="email" label="Email" onChange={onEmailChange} type="email" value={email} variant="outlined" />
          <TextField
            id="password"
            label="Password"
            onChange={onPasswordChange}
            type="password"
            value={password}
            variant="outlined"
          />
          <TextField
            id="invite"
            spellCheck={false}
            label="Invite Code"
            onChange={onInviteCodeChange}
            type="text"
            value={inviteCode}
            variant="outlined"
          />
          <Box display="flex">
            <Box display="flex" flexGrow={1} margin={1}>
              <Button component={RouterLink} fullWidth to="/account/signin" variant="outlined">
                Sign In
              </Button>
            </Box>
            <Box display="flex" flexGrow={2} margin={1}>
              <Button disabled={inviteCode !== 'hashme'} fullWidth onClick={onCreate} variant="contained">
                Create Account
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </BasePage>
  )
}

export default CreatePage
