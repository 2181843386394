import { BoxProps, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { FlexRow } from 'sdk-xy-react'

interface Props extends BoxProps {
  createDate?: string
}

const CreateDateViewer: React.FC<Props> = (props) => {
  const { createDate, ...boxProps } = props
  const createDateTime = createDate ? DateTime.fromISO(createDate) : undefined
  return (
    <FlexRow {...boxProps}>
      <Typography>{createDateTime?.toLocaleString(DateTime.DATE_FULL) ?? '--'}</Typography>
    </FlexRow>
  )
}

export default CreateDateViewer
