import { IconButton } from '@material-ui/core'
import React from 'react'
import { RiBracesLine } from 'react-icons/ri'
import { EmailEx, LifehashConnectionProviders } from 'sdk-lifehash-js'
import { FlexGrowCol, FlexGrowPaper, FlexRow } from 'sdk-xy-react'

import { DetailsJsonViewer, InfoViewer, ScoreViewer } from './Viewer'

interface Props {
  providers?: LifehashConnectionProviders[]
  email: EmailEx
}

const ListItem: React.FC<Props> = (props) => {
  const { email, providers } = props
  const [jsonOpen, setJsonOpen] = React.useState(false)

  const onJson = () => {
    setJsonOpen(!jsonOpen)
  }

  return (
    <FlexGrowPaper padding={1} variant="outlined">
      <FlexGrowCol alignItems="stretch">
        <FlexRow>
          <InfoViewer email={email.address} providers={providers} />
          <FlexRow marginX={0.5}>
            <IconButton onClick={onJson}>
              <RiBracesLine size={24} />
            </IconButton>
          </FlexRow>
          <ScoreViewer value={10} margin={0.5} />
        </FlexRow>
        <DetailsJsonViewer json={email} open={jsonOpen} margin={1} />
      </FlexGrowCol>
    </FlexGrowPaper>
  )
}

export default ListItem
