import { BoxProps, Button } from '@material-ui/core'
import React from 'react'
import { FaUser } from 'react-icons/fa'
import { FlexRow } from 'sdk-xy-react'

interface Props extends BoxProps {
  link?: string
  picture?: string
  size?: number
}

const ProfilePictureViewer: React.FC<Props> = (props) => {
  const { link, picture, size = 64, ...boxProps } = props
  return (
    <FlexRow {...boxProps}>
      <Button style={{ padding: 0 }} href={link ?? '/profile'} target="_blank">
        {picture ? (
          <img src={picture} height={size} width={size} />
        ) : (
          <FaUser style={{ opacity: 0.05, padding: size * 0.125 }} size={size * 0.75} />
        )}
      </Button>
    </FlexRow>
  )
}

export default ProfilePictureViewer
