import { BoxProps } from '@material-ui/core'
import React from 'react'
import { LifehashConnectionProviders } from 'sdk-lifehash-js'
import { FlexRow } from 'sdk-xy-react'

import Providers from '../Connections/Providers'

interface Props extends BoxProps {
  provider: LifehashConnectionProviders
  size?: number
}

const ProviderIcon: React.FC<Props> = (props) => {
  const { size = 48, provider, ...boxProps } = props

  const icon = Providers[provider]?.icon
  return <FlexRow {...boxProps}>{icon?.({ size, style: { opacity: 0.1, transform: 'rotate(-45deg)' } })}</FlexRow>
}

export default ProviderIcon
