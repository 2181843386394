import { BoxProps } from '@material-ui/core'
import React from 'react'
import { FlexPaper, NumberStatus } from 'sdk-xy-react'

interface Props extends BoxProps {
  size?: number
  value?: number | string
  title?: string
}

const StatViewer: React.FC<Props> = (props) => {
  const { title, value, size = 64, ...boxProps } = props
  return (
    <FlexPaper {...boxProps}>
      <NumberStatus value={value ?? '-'} size={size} title={title} />
    </FlexPaper>
  )
}

export default StatViewer
