import {
  AiFillAmazonSquare,
  AiFillApple,
  AiFillDropboxSquare,
  AiFillFacebook,
  AiFillGithub,
  AiFillGitlab,
  AiFillGoogleSquare,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillRedditSquare,
  AiFillTwitterSquare,
  AiFillWechat,
  AiFillYahoo,
} from 'react-icons/ai'
import {
  FaBattleNet,
  FaCcStripe,
  FaDiscord,
  FaEvernote,
  FaFlickr,
  FaFoursquare,
  FaPaypal,
  FaSpotify,
  FaTumblrSquare,
  FaTwitch,
  FaYammer,
  FaYelp,
} from 'react-icons/fa'
import { ImBasecamp } from 'react-icons/im'
import { IoLogoBitbucket } from 'react-icons/io'
import { IconType } from 'react-icons/lib'
import { SiNetflix } from 'react-icons/si'
import { TiVendorMicrosoft } from 'react-icons/ti'
import { LifehashConnectionProviders } from 'sdk-lifehash-js'

class ProviderInfo {
  public icon: IconType
  public provider: LifehashConnectionProviders
  public enabled: boolean

  constructor(params: { icon: IconType; provider: LifehashConnectionProviders }) {
    this.icon = params.icon
    this.provider = params.provider
    this.enabled = false
  }

  enable() {
    this.enabled = true
    return this
  }
}

const Providers: { [index: string]: ProviderInfo } = {
  [LifehashConnectionProviders.Google]: new ProviderInfo({
    icon: AiFillGoogleSquare,
    provider: LifehashConnectionProviders.Google,
  }).enable(),
  [LifehashConnectionProviders.Github]: new ProviderInfo({
    icon: AiFillGithub,
    provider: LifehashConnectionProviders.Github,
  }).enable(),
  [LifehashConnectionProviders.Facebook]: new ProviderInfo({
    icon: AiFillFacebook,
    provider: LifehashConnectionProviders.Facebook,
  }).enable(),
  [LifehashConnectionProviders.Twitter]: new ProviderInfo({
    icon: AiFillTwitterSquare,
    provider: LifehashConnectionProviders.Twitter,
  }).enable(),
  [LifehashConnectionProviders.Linkedin]: new ProviderInfo({
    icon: AiFillLinkedin,
    provider: LifehashConnectionProviders.Linkedin,
  }).enable(),
  [LifehashConnectionProviders.Instagram]: new ProviderInfo({
    icon: AiFillInstagram,
    provider: LifehashConnectionProviders.Instagram,
  }),
  [LifehashConnectionProviders.Amazon]: new ProviderInfo({
    icon: AiFillAmazonSquare,
    provider: LifehashConnectionProviders.Amazon,
  }),
  [LifehashConnectionProviders.Apple]: new ProviderInfo({
    icon: AiFillApple,
    provider: LifehashConnectionProviders.Apple,
  }).enable(),
  [LifehashConnectionProviders.Dropbox]: new ProviderInfo({
    icon: AiFillDropboxSquare,
    provider: LifehashConnectionProviders.Dropbox,
  }),
  [LifehashConnectionProviders.Gitlab]: new ProviderInfo({
    icon: AiFillGitlab,
    provider: LifehashConnectionProviders.Gitlab,
  }),
  [LifehashConnectionProviders.Reddit]: new ProviderInfo({
    icon: AiFillRedditSquare,
    provider: LifehashConnectionProviders.Reddit,
  }),
  [LifehashConnectionProviders.Wechat]: new ProviderInfo({
    icon: AiFillWechat,
    provider: LifehashConnectionProviders.Wechat,
  }),
  [LifehashConnectionProviders.Yahoo]: new ProviderInfo({
    icon: AiFillYahoo,
    provider: LifehashConnectionProviders.Yahoo,
  }).enable(),
  [LifehashConnectionProviders.Microsoft]: new ProviderInfo({
    icon: TiVendorMicrosoft,
    provider: LifehashConnectionProviders.Microsoft,
  }),
  [LifehashConnectionProviders.Bitbucket]: new ProviderInfo({
    icon: IoLogoBitbucket,
    provider: LifehashConnectionProviders.Bitbucket,
  }),
  [LifehashConnectionProviders.Basecamp]: new ProviderInfo({
    icon: ImBasecamp,
    provider: LifehashConnectionProviders.Basecamp,
  }),
  [LifehashConnectionProviders.Netflix]: new ProviderInfo({
    icon: SiNetflix,
    provider: LifehashConnectionProviders.Netflix,
  }),
  [LifehashConnectionProviders.Evernote]: new ProviderInfo({
    icon: FaEvernote,
    provider: LifehashConnectionProviders.Evernote,
  }),
  [LifehashConnectionProviders.BattleNet]: new ProviderInfo({
    icon: FaBattleNet,
    provider: LifehashConnectionProviders.BattleNet,
  }),
  [LifehashConnectionProviders.Discord]: new ProviderInfo({
    icon: FaDiscord,
    provider: LifehashConnectionProviders.Discord,
  }),
  [LifehashConnectionProviders.Foursquare]: new ProviderInfo({
    icon: FaFoursquare,
    provider: LifehashConnectionProviders.Foursquare,
  }),
  [LifehashConnectionProviders.Flickr]: new ProviderInfo({
    icon: FaFlickr,
    provider: LifehashConnectionProviders.Flickr,
  }),
  [LifehashConnectionProviders.Paypal]: new ProviderInfo({
    icon: FaPaypal,
    provider: LifehashConnectionProviders.Paypal,
  }),
  [LifehashConnectionProviders.Spotify]: new ProviderInfo({
    icon: FaSpotify,
    provider: LifehashConnectionProviders.Spotify,
  }),
  [LifehashConnectionProviders.Stripe]: new ProviderInfo({
    icon: FaCcStripe,
    provider: LifehashConnectionProviders.Stripe,
  }),
  [LifehashConnectionProviders.Tumblr]: new ProviderInfo({
    icon: FaTumblrSquare,
    provider: LifehashConnectionProviders.Tumblr,
  }),
  [LifehashConnectionProviders.Twitch]: new ProviderInfo({
    icon: FaTwitch,
    provider: LifehashConnectionProviders.Twitch,
  }),
  [LifehashConnectionProviders.Yammer]: new ProviderInfo({
    icon: FaYammer,
    provider: LifehashConnectionProviders.Yammer,
  }),
  [LifehashConnectionProviders.Yelp]: new ProviderInfo({ icon: FaYelp, provider: LifehashConnectionProviders.Yelp }),
}

export default Providers
