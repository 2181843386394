import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { FlexRow } from 'sdk-xy-react'

import BasePage from '../BasePage'
import AccountScoreDashCard from './Details/AccountScoreDashCard'
import ConnectionsDashCard from './Details/ConnectionsDashCard'
import DataCardDashCard from './Details/DataCardDashCard'
import EmailsDashCard from './Details/EmailsDashCard'
import TelephonesDashCard from './Details/TelephonesDashCard'

const StandardProfilePage: React.FC = () => {
  return (
    <BasePage requireAuth={true}>
      <Container>
        <FlexRow marginY={2}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <DataCardDashCard />
            </Grid>
            <Grid item>
              <AccountScoreDashCard />
            </Grid>
            <Grid item>
              <ConnectionsDashCard />
            </Grid>
            <Grid item>
              <EmailsDashCard />
            </Grid>
            <Grid item>
              <TelephonesDashCard />
            </Grid>
          </Grid>
        </FlexRow>
      </Container>
    </BasePage>
  )
}

export default StandardProfilePage
