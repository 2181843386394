import { CardProps, useTheme } from '@material-ui/core'
import React from 'react'
import { FlexGrowCol, FlexGrowRow, FlexRow } from 'sdk-xy-react'

import { CoverProgress, GenderViewer, PictureViewer } from '../../Components'
import ApiContext from '../../Contexts/ApiContext'
import { useCompoundPerson } from '../../Hooks'
import DashCard from './DashCard'

const dashboardUnitSize = 150

const DataCardDashCard: React.FC<CardProps> = (props) => {
  const theme = useTheme()
  const { person, error } = useCompoundPerson()
  const { refreshAll } = React.useContext(ApiContext)

  const onRefresh = () => {
    refreshAll?.()
  }

  const onInfo = () => {
    refreshAll?.()
  }

  const onEdit = () => {
    refreshAll?.()
  }

  return (
    <DashCard {...props} onRefresh={onRefresh} onInfo={onInfo} onEdit={onEdit}>
      <FlexRow fontFamily={theme.typography.fontFamily}>
        <PictureViewer picture={person?.image} size={dashboardUnitSize - theme.spacing() * 2} />
        <FlexGrowCol margin={1} alignItems="flex-end" justifyContent="flex-start" position="relative">
          <FlexRow fontSize={18}>Data Card</FlexRow>
          <FlexRow fontSize={14}>{person?.name}</FlexRow>
          <GenderViewer gender={person?.gender} />
          <FlexGrowRow />
          <FlexRow fontSize={10}>{person?.email}</FlexRow>
          <FlexRow fontSize={14}>{person?.telephone}</FlexRow>
          <CoverProgress open={!person} errors={error ? [error] : undefined} onRetry={onRefresh} />
        </FlexGrowCol>
      </FlexRow>
    </DashCard>
  )
}

export default DataCardDashCard
