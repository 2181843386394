import { BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { FlexRow } from 'sdk-xy-react'

interface Props extends BoxProps {
  full?: string
  given?: string
  middle?: string
  family?: string
}

const NameViewer: React.FC<Props> = (props) => {
  const { full, given, family, ...boxProps } = props
  let name = full
  if (!name) {
    if (given) {
      if (family) {
        name = `${given} ${family}`
      } else {
        name = given
      }
    } else {
      name = family ?? '--'
    }
  }
  return (
    <FlexRow {...boxProps}>
      <Typography>{name}</Typography>
    </FlexRow>
  )
}

export default NameViewer
