import React from 'react'

export interface AppSettings {
  darkMode?: boolean
  enableDarkMode?: (enabled: boolean) => void
  denseMode?: boolean
  enableDenseMode?: (enabled: boolean) => void
}

const AppSettingsContext = React.createContext<AppSettings>({})
export default AppSettingsContext
