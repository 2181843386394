import { BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { FlexGrowRow, FlexPaper } from 'sdk-xy-react'

interface DeltaProps extends BoxProps {
  label: string
  change: number
}

const Delta: React.FC<DeltaProps> = (props) => {
  const { label, change, ...paperProps } = props
  const changeText = change > 0 ? `+${change}` : `${change}`
  return (
    <FlexPaper variant="outlined" {...paperProps}>
      <FlexGrowRow color={change < 0 ? 'red' : change > 0 ? 'green' : 'grey'} justifyContent="flex-end" flexBasis={40}>
        <Typography variant="caption">{changeText}</Typography>
      </FlexGrowRow>
      <FlexGrowRow justifyContent="center" marginLeft={1} flexBasis={60} bgcolor="#ccc">
        <Typography variant="caption">{label}</Typography>
      </FlexGrowRow>
    </FlexPaper>
  )
}

export default Delta
