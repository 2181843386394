import firebase from 'firebase'
import React, { ReactElement, useEffect, useState } from 'react'

import FirebaseContext from '../Contexts/FirebaseContext'
import XyoFirebaseWrapper from '../lib/XyoFirebaseWrapper'

interface FirebaseLoaderProps {
  children?: ReactElement[] | ReactElement | undefined
}

const options = {
  apiKey: 'AIzaSyC6rNsTzEGUjNLuwnPpcb_-_Z62ah_iDus',
  appId: '1:1089950293477:web:79ba7470edcc13fea3630c',
  authDomain: 'lifehash-297217.firebaseapp.com',
  databaseURL: 'https://lifehash-297217.firebaseio.com',
  measurementId: 'G-6S9ZMTSKNL',
  messagingSenderId: '1089950293477',
  projectId: 'lifehash-297217',
  storageBucket: 'lifehash-297217.appspot.com',
}

const FirebaseLoader: React.FC<FirebaseLoaderProps> = (props) => {
  const [firebaseWrapper, setFirebaseWrapper] = useState<XyoFirebaseWrapper>()
  const [signedIn, setSignedIn] = useState<boolean>()

  const [user, setUser] = useState<firebase.User | null>()

  useEffect(() => {
    let cancelled = false
    let onAuthStateChangedUnsubscribe: firebase.Unsubscribe
    const load = async () => {
      const firebaseWrapper = await XyoFirebaseWrapper.get(options)
      if (!cancelled) {
        setFirebaseWrapper(firebaseWrapper)
      }
      if (firebaseWrapper) {
        onAuthStateChangedUnsubscribe = firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
          console.log(`onAuthStateChanged: ${user}`)
          if (!cancelled) {
            setUser(user)
            setSignedIn(!!user?.email)
          }
        })
      }
    }
    load()
    return () => {
      onAuthStateChangedUnsubscribe?.()
      cancelled = true
    }
  }, [])

  return <FirebaseContext.Provider value={{ firebaseWrapper, signedIn, user }} {...props} />
}

export default FirebaseLoader
