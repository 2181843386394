import React from 'react'
import { LifehashConnectionApiWrapper, LifehashError } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import ApiContext from '../Contexts/ApiContext'

const useConnections = () => {
  const [connections, setConnections] = React.useState<LifehashConnectionApiWrapper[]>()
  const [error, setError] = React.useState<LifehashError>()

  const { api } = React.useContext(ApiContext)

  useAsyncEffect(
    async (isMounted) => {
      setError(undefined)
      setConnections(undefined)
      if (api) {
        try {
          const connections = await api.getConnections()
          if (isMounted()) {
            setConnections(connections)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [api]
  )

  return { connections, error }
}

export default useConnections
