import { BoxProps } from '@material-ui/core'
import React from 'react'
import { LifehashConnectionCompoundId } from 'sdk-lifehash-js'
import { assertEx, FlexGrowCol, FlexGrowRow } from 'sdk-xy-react'

import CoverProgress from '../Components/CoverProgress'
import ConnectionLoader from '../ContextLoaders/ConnectionLoader'
import PersonLoader from '../ContextLoaders/PersonLoader'
import ApiContext from '../Contexts/ApiContext'
import FirebaseContext from '../Contexts/FirebaseContext'
import { useConnections } from '../Hooks'
import AddConnectionButtons from './AddConnectionButtons'
import { ConnectionDetailsListItem } from './Details'

const ConnectionList: React.FC<BoxProps> = (props) => {
  const { user } = React.useContext(FirebaseContext)
  const { connections, error } = useConnections()
  const { refreshAll } = React.useContext(ApiContext)
  const onErrorRetry = () => {
    refreshAll?.()
  }

  const lifehash = assertEx(user?.uid)

  return (
    <FlexGrowCol {...props} position="relative">
      <FlexGrowCol marginY={1} alignItems="stretch" justifyContent="flex-start">
        {connections?.map((connection) => {
          const id: LifehashConnectionCompoundId = {
            lifehash,
            provider: connection.provider(),
            provider_unique_id: connection.providerId(),
          }
          return (
            <FlexGrowRow marginY={1} key={connection.providerId()}>
              <ConnectionLoader id={id}>
                <PersonLoader>
                  <ConnectionDetailsListItem />
                </PersonLoader>
              </ConnectionLoader>
            </FlexGrowRow>
          )
        })}
      </FlexGrowCol>
      <AddConnectionButtons />
      <CoverProgress paper={false} open={!connections} errors={error ? [error] : undefined} onRetry={onErrorRetry} />
    </FlexGrowCol>
  )
}

export default ConnectionList
