import { Box, BoxProps, Container, Link, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { FlexCol, FlexGrowCol, FlexGrowRow, FlexRow } from 'sdk-xy-react'

import FirebaseContext from '../Contexts/FirebaseContext'

const About: React.FC<BoxProps> = (props) => {
  const { firebaseWrapper } = useContext(FirebaseContext)
  const currentUser = firebaseWrapper?.currentUser()
  return (
    <FlexCol {...props}>
      <Typography variant="subtitle1">Lifehash.org</Typography>
      <FlexRow padding={1}>
        <Link target="_blank" href="https://lifehash.org/about" variant="caption" color="inherit">
          About
        </Link>
      </FlexRow>
      <FlexRow padding={1} display={currentUser ? 'none' : 'block'}>
        <Link component={RouterLink} to="/account/signin" variant="caption" color="inherit">
          Sign In
        </Link>
      </FlexRow>
      <FlexRow padding={1} display={currentUser ? 'block' : 'none'}>
        <Link component={RouterLink} to="/account/signout" variant="caption" color="inherit">
          Sign Out
        </Link>
      </FlexRow>
    </FlexCol>
  )
}

const GetStarted: React.FC<BoxProps> = (props) => (
  <FlexCol {...props}>
    <Typography variant="subtitle1">Get Started</Typography>
    <FlexRow padding={1}>
      <Link component={RouterLink} to="/account/create" variant="caption" color="inherit">
        Create Account
      </Link>
    </FlexRow>
    <FlexRow padding={1}>
      <Link target="_blank" href="https://lifehash.org/support" variant="caption" color="inherit">
        Support
      </Link>
    </FlexRow>
  </FlexCol>
)

const Copyright: React.FC<BoxProps> = (props) => {
  return (
    <FlexRow {...props}>
      <Typography component={Box} padding={1} variant="subtitle2">
        Copyright &copy; 2020{' '}
        <Link target="_blank" color="inherit" href="https://lifehash.org/about/">
          Lifehash.org
        </Link>
        {' | '}
        <Link target="_blank" color="inherit" href="https://lifehash.org/privacy/">
          Privacy Policy
        </Link>
        {' | '}
        <Link target="_blank" color="inherit" href="https://lifehash.org/terms/">
          Terms of Service
        </Link>
      </Typography>
    </FlexRow>
  )
}

const Footer: React.FC = () => {
  return (
    <Container>
      <FlexGrowCol paddingTop={8}>
        <FlexGrowRow flexWrap="wrap" justifyContent="space-between">
          <About marginX={2} alignItems="flex-start" />
          <FlexGrowRow />
          <GetStarted marginX={2} alignItems="flex-end" />
        </FlexGrowRow>
        <Copyright />
      </FlexGrowCol>
    </Container>
  )
}

export default Footer
