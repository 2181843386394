import { Button, CardProps, useTheme } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import { CoverProgress, StatViewer } from '../../Components'
import ApiContext from '../../Contexts/ApiContext'
import { useTelephones } from '../../Hooks'
import DashCard from './DashCard'

const dashboardUnitSize = 150

const TelephonesDashCard: React.FC<CardProps> = (props) => {
  const theme = useTheme()
  const { telephones, error } = useTelephones()
  const { refreshAll } = React.useContext(ApiContext)

  const onRefresh = () => {
    refreshAll?.()
  }

  const onInfo = () => {
    refreshAll?.()
  }

  const onAdd = () => {
    refreshAll?.()
  }

  return (
    <DashCard {...props} onInfo={onInfo} onRefresh={onRefresh} onAdd={onAdd}>
      <FlexRow justifyContent="space-between">
        <StatViewer
          title="Telephones"
          value={telephones?.length ?? '-'}
          size={dashboardUnitSize - theme.spacing() * 2}
        />
        <FlexGrowCol marginLeft={2} alignItems="stretch" justifyContent="flex-start" position="relative">
          <FlexRow margin={1}>
            <Button component={Link} to="/telephones" fullWidth variant="outlined">
              Add
            </Button>
          </FlexRow>
          <FlexRow margin={1}>
            <Button component={Link} to="/telephones" fullWidth variant="outlined">
              See All
            </Button>
          </FlexRow>
          <CoverProgress open={!telephones} errors={error ? [error] : undefined} onRetry={onRefresh} />
        </FlexGrowCol>
      </FlexRow>
    </DashCard>
  )
}

export default TelephonesDashCard
