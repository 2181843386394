import { Button, Typography } from '@material-ui/core'
import React from 'react'
import { FlexCol } from 'sdk-xy-react'

import ConnectionPage from './OAuthPage'

const GoogleCallback: React.FC = () => {
  const onParse = () => {
    console.log(`URI: ${document.location.href}`)
  }

  return (
    <ConnectionPage requireAuth={true}>
      <FlexCol justifyContent="flex-start" width="100vw">
        <Typography variant="h1">Google Accounts</Typography>
        <Button onClick={onParse} variant="outlined">
          Get Token
        </Button>
      </FlexCol>
    </ConnectionPage>
  )
}

export default GoogleCallback
