import { BoxProps } from '@material-ui/core'
import StatusCodes from 'http-status-codes'
import React from 'react'
import { LifehashError, LifehashErrorCause, PersonEx } from 'sdk-lifehash-js'
import { FlexGrowCol, FlexRow } from 'sdk-xy-react'

import CoverProgress from '../../../Components/CoverProgress'
import GenderViewer from '../../../Components/Viewers/Gender'
import Pipe from '../Pipe'
import CreateDateViewer from './CreateDate'
import EmailViewer from './Email'
import NameViewer from './Name'
import ProfilePictureViewer from './ProfilePicture'

interface Props extends BoxProps {
  person?: PersonEx
  error?: LifehashError
}

const prettyError = (error: LifehashError) => {
  if (error.status === StatusCodes.FORBIDDEN) {
    if (error.cause === LifehashErrorCause.Disconnected) {
      return new LifehashError(
        'Disconnected',
        Error('This account seems to be disconnected.  Please remove or re-add the account.')
      )
    }
  }
  return error
}

const InfoViewer: React.FC<Props> = (props) => {
  const { person, error, ...boxProps } = props
  const { url, givenName, familyName, name, email, gender, emailVerified, image, createDate, emailPublic } =
    person ?? {}

  return (
    <FlexGrowCol {...boxProps} alignItems="stretch" position="relative">
      <FlexRow justifyContent="flex-start">
        <ProfilePictureViewer marginX={1} picture={image} link={url} size={64} />
        <NameViewer marginX={1} full={name} given={givenName} family={familyName} />
        <GenderViewer gender={gender} />
        <Pipe />
        <EmailViewer marginX={0.5} email={email} verified={emailVerified} emailPublic={emailPublic} />
        <Pipe />
        <CreateDateViewer marginX={0.5} createDate={createDate} />
      </FlexRow>
      <CoverProgress open={!person} errors={error ? [prettyError(error)] : undefined} />
    </FlexGrowCol>
  )
}

export default InfoViewer
