import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ListPage from './ListPage'

const Emails: React.FC = () => {
  return (
    <Switch>
      <Route component={ListPage} exact path="/telephones" />
      <Route>
        <Redirect to={'/'} />
      </Route>
    </Switch>
  )
}

export default Emails
