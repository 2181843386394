import React, { useContext } from 'react'
import { LifehashConnectionCompoundId } from 'sdk-lifehash-js'

import ConnectionContext from '../Contexts/ConnectionContext'
import PersonContext from '../Contexts/PersonContext'
import { usePerson } from '../Hooks'

interface Props {
  id?: LifehashConnectionCompoundId
}

const PersonLoader: React.FC<Props> = (props) => {
  const { id } = useContext(ConnectionContext)
  const activeId = props.id ?? id
  const { person, error, refresh } = activeId
    ? usePerson(activeId)
    : { error: undefined, person: undefined, refresh: undefined }

  return <PersonContext.Provider value={{ error, person, refresh }} {...props} />
}

export default PersonLoader
