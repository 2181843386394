import { Box, Typography } from '@material-ui/core'
import React, { useContext } from 'react'

import BasePage from '../BasePage'
import FirebaseContext from '../Contexts/FirebaseContext'

const SignoutPage: React.FC = () => {
  const { firebaseWrapper } = useContext(FirebaseContext)

  firebaseWrapper?.getAuth()?.signOut()

  return (
    <BasePage>
      <Box alignItems="center" display="flex" flexDirection="column" flexGrow={1} justifyContent="center" width="100vw">
        <Typography variant="h1">You have been signed out</Typography>
      </Box>
    </BasePage>
  )
}

export default SignoutPage
