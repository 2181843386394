import firebase from 'firebase'
import React from 'react'

import XyoFirebaseWrapper from '../lib/XyoFirebaseWrapper'

interface FirebaseContextProps {
  firebaseWrapper?: XyoFirebaseWrapper
  user?: firebase.User | null
  signedIn?: boolean
}

const FirebaseContext = React.createContext<FirebaseContextProps>({})
export default FirebaseContext
