import React, { PropsWithChildren, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { FlexGrowCol } from 'sdk-xy-react'

import FirebaseContext from './Contexts/FirebaseContext'

interface BasePageProps {
  requireAuth?: boolean
  noindex?: boolean
  title?: string
}

const BasePage: React.FC<PropsWithChildren<BasePageProps>> = (props) => {
  const { requireAuth = false, children, noindex = false, title = 'Your Data, Your Rules' } = props
  const { signedIn } = useContext(FirebaseContext)

  if (signedIn === undefined) {
    return <FlexGrowCol />
  } else if (!signedIn && requireAuth) {
    return <Redirect to="/account/signin" />
  }

  return (
    <FlexGrowCol justifyContent="flex-start">
      <Helmet title={`Lifehash: ${title}`}>{noindex ? <meta content="noindex" name="robots" /> : null}</Helmet>
      {children}
    </FlexGrowCol>
  )
}

export default BasePage
