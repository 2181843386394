import React, { useState } from 'react'
import { LifehashConnectionCompoundId, LifehashError, PersonEx } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import ApiContext from '../Contexts/ApiContext'

const usePerson = (id: LifehashConnectionCompoundId) => {
  const [person, setPerson] = React.useState<PersonEx>()

  const apiContext = React.useContext(ApiContext)
  const api = apiContext.api
  const apiError = apiContext.error
  const [refreshCount, setRefreshCount] = useState(0)

  const [error, setError] = React.useState<LifehashError>()

  React.useEffect(() => {
    setError(undefined)
    if (apiError) {
      setError(apiError)
      setPerson(undefined)
    }
  }, [apiError])

  useAsyncEffect(
    async (isMounted) => {
      setError(undefined)
      setPerson(undefined)
      if (api) {
        try {
          const connection = api.connection(id?.provider, id?.provider_unique_id)
          const person =
            refreshCount === 0 ? await connection?.userInfo().get() : await connection?.userInfo().fetch(true)
          if (isMounted()) {
            setPerson(person)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [api, refreshCount]
  )

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
  }

  return { error, person, refresh }
}

export default usePerson
