import { Typography } from '@material-ui/core'
import React from 'react'
import { FlexRow } from 'sdk-xy-react'

const Pipe: React.FC = () => {
  return (
    <FlexRow marginX={0.5}>
      <Typography>|</Typography>
    </FlexRow>
  )
}

export default Pipe
