import React, { PropsWithChildren, useContext, useState } from 'react'
import { ApiStage, LifehashApiWrapper, LifehashConnectionProviders, LifehashError } from 'sdk-lifehash-js'
import { isLocalhost } from 'sdk-xy-react'
import useAsyncEffect from 'use-async-effect'

import ApiContext from '../Contexts/ApiContext'
import FirebaseContext from '../Contexts/FirebaseContext'

interface ApiLoaderProps {
  uri?: string
}

const ApiLoader: React.FC<PropsWithChildren<ApiLoaderProps>> = (props) => {
  const { user } = useContext(FirebaseContext)
  const apiStage = isLocalhost
    ? ApiStage.Local
    : document.location.hostname.startsWith('beta')
    ? ApiStage.Beta
    : ApiStage.Prod

  const apiDomain = isLocalhost
    ? 'http://localhost:3030/dev'
    : document.location.hostname.startsWith('beta')
    ? 'https://beta-api.lifehash.org'
    : 'https://api.lifehash.org'

  const [api, setApi] = useState<LifehashApiWrapper>()
  const [refreshCount, setRefreshCount] = useState(0)
  const [error, setError] = useState<LifehashError>()

  const refreshAll = () => {
    setError(undefined)
    setApi(undefined)
    setRefreshCount(refreshCount + 1)
  }

  const removeConnection = async (provider: LifehashConnectionProviders, providerId: string) => {
    setError(undefined)
    setApi(undefined)
    await api?.removeConnection(provider, providerId)
    setRefreshCount(refreshCount + 1)
  }

  const refreshConnection = async (provider: LifehashConnectionProviders, providerId: string) => {
    await api?.connection(provider, providerId)?.userInfo().fetch(true)
  }

  useAsyncEffect(
    async (isMounted) => {
      const token = await user?.getIdToken()
      const lifehash = user?.uid
      if (apiDomain && token && lifehash) {
        const api = LifehashApiWrapper.get({ apiDomain, lifehash, token })
        try {
          if (isMounted()) {
            setApi(api)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [apiDomain, user, refreshCount]
  )

  return (
    <ApiContext.Provider
      value={{
        api,
        apiDomain,
        apiStage,
        error,
        refreshAll,
        refreshConnection,
        removeConnection,
      }}
      {...props}
    />
  )
}

export default ApiLoader
