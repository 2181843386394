import React from 'react'
import { EmailEx, LifehashError } from 'sdk-lifehash-js'

interface PersonContextProps {
  person?: EmailEx
  error?: LifehashError
  refresh?: () => void
}

const PersonContext = React.createContext<PersonContextProps>({})
export default PersonContext
