import React, { ReactElement, useState } from 'react'

import AppSettingsContext from '../Contexts/AppSettingsContext'

interface AppSettingsLoaderProps {
  children?: ReactElement[] | ReactElement | undefined
}

const AppSettingsLocalStoragePrefix = 'LifeHashSetting|'

enum Settings {
  DarkMode = 'DarkMode',
  DenseMode = 'DenseMode',
}

const AppSettingsLoader: React.FC<AppSettingsLoaderProps> = (props) => {
  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.getItem(`${AppSettingsLocalStoragePrefix}${Settings.DarkMode}`) === 'true'
  )
  const [denseMode, setDenseMode] = useState<boolean>(
    localStorage.getItem(`${AppSettingsLocalStoragePrefix}${Settings.DenseMode}`) === 'true'
  )

  const enableDarkMode = (enabled: boolean) => {
    console.log('AppSettingsLoader:enableInfuraAsDefaultProvider')
    localStorage.setItem(`${AppSettingsLocalStoragePrefix}${Settings.DarkMode}`, enabled ? 'true' : 'false')
    setDarkMode(enabled)
  }

  const enableDenseMode = (enabled: boolean) => {
    console.log('AppSettingsLoader:enableInfuraAsDefaultProvider')
    localStorage.setItem(`${AppSettingsLocalStoragePrefix}${Settings.DarkMode}`, enabled ? 'true' : 'false')
    setDenseMode(enabled)
  }

  return <AppSettingsContext.Provider value={{ darkMode, denseMode, enableDarkMode, enableDenseMode }} {...props} />
}

export default AppSettingsLoader
