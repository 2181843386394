import { BoxProps } from '@material-ui/core'
import React from 'react'
import { IoMdFemale, IoMdMale, IoMdTransgender } from 'react-icons/io'
import { Gender } from 'sdk-lifehash-js'
import { FlexRow } from 'sdk-xy-react'

interface GenderIconProps {
  gender?: Gender
}

const GenderIcon: React.FC<GenderIconProps> = (props) => {
  const { gender } = props
  switch (gender) {
    case Gender.Female:
      return <IoMdFemale />
    case Gender.Male:
      return <IoMdMale />
    case Gender.Trans:
      return <IoMdTransgender />
    default:
      return null
  }
}

interface GenderViewerProps extends GenderIconProps, BoxProps {}

const GenderViewer: React.FC<GenderViewerProps> = (props) => {
  const { gender, ...boxProps } = props
  return (
    <FlexRow {...boxProps}>
      <GenderIcon gender={gender} />
    </FlexRow>
  )
}

export default GenderViewer
