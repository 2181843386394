import { Box, useTheme } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Switch } from 'react-router-dom'

import GoogleCallback from './GoogleCallback'

const OAuth: React.FC = () => {
  const theme = useTheme()

  return (
    <Box bgcolor={theme.palette.background.default}>
      <Helmet defaultTitle="Lifehash" titleTemplate="%s | Lifehash" />
      <Switch>
        <Route component={GoogleCallback} exact path="/auth/google/callback" />
        <Route>
          <Redirect to={'/'} />
        </Route>
      </Switch>
    </Box>
  )
}

export default OAuth
