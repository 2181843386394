import React, { useContext, useEffect, useState } from 'react'
import { LifehashConnectionApiWrapper, LifehashConnectionCompoundId } from 'sdk-lifehash-js'

import ApiContext from '../Contexts/ApiContext'
import ConnectionContext from '../Contexts/ConnectionContext'

interface Props {
  id: LifehashConnectionCompoundId
}

const ConnectionLoader: React.FC<Props> = (props) => {
  const { id } = props
  const { api, error } = useContext(ApiContext)
  const [connection, setConnection] = useState<LifehashConnectionApiWrapper>()

  useEffect(() => {
    setConnection(undefined)
    if (api) {
      setConnection(api.connection(id.provider, id.provider_unique_id))
    }
  }, [api, id])

  return <ConnectionContext.Provider value={{ connection, error, id }} {...props} />
}

export default ConnectionLoader
