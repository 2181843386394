import { BoxProps } from '@material-ui/core'
import React from 'react'
import { FlexPaper, NumberStatus } from 'sdk-xy-react'

interface Props extends BoxProps {
  size?: number
  value?: number
}

const ScoreViewer: React.FC<Props> = (props) => {
  const { value, size = 64, ...boxProps } = props
  return (
    <FlexPaper {...boxProps}>
      <NumberStatus value={value} size={size} title="Score" />
    </FlexPaper>
  )
}

export default ScoreViewer
