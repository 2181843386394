import { Container } from '@material-ui/core'
import React from 'react'

import BasePage from '../BasePage'
import ConnectionList from './ConnectionList'

const ConnectionListPage: React.FC = () => {
  return (
    <BasePage requireAuth={true}>
      <Container>
        <ConnectionList />
      </Container>
    </BasePage>
  )
}

export default ConnectionListPage
