import { BoxProps, IconButton } from '@material-ui/core'
import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FlexGrowCol, FlexGrowRow, FlexRow } from 'sdk-xy-react'

import { CoverProgress } from '../Components'
import ListItem from './Details/ListItem'
import PersonInfo from './PersonInfo'

const PeopleList: React.FC<BoxProps> = (props) => {
  const items: PersonInfo[] = [{ firstName: 'Arie', id: 'id1', lastName: 'Trouw' }]

  return (
    <FlexGrowCol {...props} alignItems="stretch" justifyContent="flex-start" position="relative" marginY={1}>
      <FlexRow margin={1}>
        <IconButton>
          <AiOutlinePlusCircle size={40} />
        </IconButton>
      </FlexRow>
      {items?.map((item) => {
        return (
          <FlexGrowRow marginY={1} key={item.id}>
            <ListItem info={item} />
          </FlexGrowRow>
        )
      })}
      <CoverProgress open={!items} />
    </FlexGrowCol>
  )
}

export default PeopleList
