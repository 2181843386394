import { BoxProps } from '@material-ui/core'
import React from 'react'
import { FlexGrowCol, FlexGrowRow } from 'sdk-xy-react'

import { CoverProgress } from '../Components'
import AddressInfo from './AddressInfo'
import ListItem from './Details/ListItem'

const AddressList: React.FC<BoxProps> = (props) => {
  const items: AddressInfo[] = [{ address: '1405 30th Street', id: 'id1' }]

  return (
    <FlexGrowCol {...props}>
      {items?.map((item) => {
        return (
          <FlexGrowRow marginY={1} key={item.id}>
            <ListItem info={item} />
          </FlexGrowRow>
        )
      })}
      <CoverProgress open={!items} />
    </FlexGrowCol>
  )
}

export default AddressList
