import React from 'react'
import { Route, Switch } from 'react-router-dom'

import CreatePage from './CreatePage'
import SettingsPage from './SettingsPage'
import SigninPage from './SigninPage'
import SignoutPage from './SignoutPage'

const Account: React.FC = () => {
  return (
    <Switch>
      <Route component={SigninPage} path="/account/signin" />
      <Route component={SignoutPage} path="/account/signout" />
      <Route component={CreatePage} path="/account/create" />
      <Route component={SettingsPage} path="/account/settings" />
    </Switch>
  )
}

export default Account
