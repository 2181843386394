import firebase from 'firebase'

class XyoFirebaseWrapper {
  private app: firebase.app.App

  private constructor(options: any) {
    this.app = firebase.initializeApp(options)
  }

  static instance?: XyoFirebaseWrapper
  static async get(options: any) {
    this.instance = this.instance ?? new XyoFirebaseWrapper(options)
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    return this.instance
  }

  public getAuth() {
    return firebase.auth()
  }

  public currentUser() {
    return firebase.auth().currentUser
  }

  public async sendPasswordResetEmail(email: string) {
    return await firebase.auth().sendPasswordResetEmail(email)
  }

  public async signUp(email: string, password: string) {
    return await firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  public async signIn(email: string, password: string) {
    return await firebase.auth().signInWithEmailAndPassword(email, password)
  }

  public signOut() {
    return firebase.auth().signOut()
  }

  public async updatePassword(password: string) {
    try {
      const user = this.currentUser()
      if (user) {
        return await user.updatePassword(password)
      }
    } catch (err) {
      throw new Error(err)
    }

    return false
  }

  public async signInWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider()
    return await firebase.auth().signInWithPopup(provider)
  }

  public async signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return await firebase.auth().signInWithPopup(provider)
  }

  public isSignedIn() {
    return firebase.auth().currentUser != null
  }

  public async removeAlias(key: string, userId: string) {
    const currentData = (await this.fetchUserInfo(userId)).aliases || {}

    delete currentData[key]

    return await firebase.firestore().collection('network_user_info').doc(userId).update({
      aliases: currentData,
    })
  }

  public async addAlias(key: string, value: string, userId: string) {
    return await firebase
      .firestore()
      .collection('network_user_info')
      .doc(userId)
      .set(
        {
          aliases: {
            [key]: value,
          },
        },
        { merge: true }
      )
  }

  public async getAllNames(keys: string[]) {
    return await Promise.all(
      keys.map(async (key) => {
        const docRef = await this.app.firestore().collection('addresses').doc(key).get()
        return (docRef.data() as any).name
      })
    )
  }

  public async fetchUserInfo(userId: string) {
    try {
      // const userRef = firebase.firestore().collection(`users`).doc(userId)
      const userRef = firebase.firestore().collection('network_user_info').doc(userId)

      const doc = await userRef.get()
      return doc.exists ? doc.data() : {}
    } catch (error) {
      return error
    }
  }
}

export default XyoFirebaseWrapper
