import { Card, CardActions, CardContent, CardProps, IconButton } from '@material-ui/core'
import React from 'react'
import { AiOutlineEdit, AiOutlineInfoCircle, AiOutlinePlusCircle, AiOutlineReload } from 'react-icons/ai'
import { FlexGrowRow } from 'sdk-xy-react'

interface DashCardProps extends CardProps {
  height?: number
  width?: number
  onInfo?: () => void
  onRefresh?: () => void
  onAdd?: () => void
  onEdit?: () => void
}

const dashboardUnitSize = 80

const DashCard: React.FC<DashCardProps> = (props) => {
  const { children, width = 4, height = 3, onEdit, onInfo, onRefresh, onAdd, ...cardProps } = props
  return (
    <Card
      {...cardProps}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: height * dashboardUnitSize,
        width: width * dashboardUnitSize,
      }}
      variant="outlined"
    >
      <CardContent>{children}</CardContent>
      <FlexGrowRow />
      <CardActions>
        <FlexGrowRow>
          {onInfo ? (
            <IconButton onClick={onInfo}>
              <AiOutlineInfoCircle size={20} />
            </IconButton>
          ) : null}
          <FlexGrowRow />
          {onEdit ? (
            <IconButton onClick={onEdit}>
              <AiOutlineEdit size={20} />
            </IconButton>
          ) : null}
          {onAdd ? (
            <IconButton onClick={onAdd}>
              <AiOutlinePlusCircle size={20} />
            </IconButton>
          ) : null}
          {onRefresh ? (
            <IconButton onClick={onRefresh}>
              <AiOutlineReload size={20} />
            </IconButton>
          ) : null}
        </FlexGrowRow>
      </CardActions>
    </Card>
  )
}

export default DashCard
