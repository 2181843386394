import { useTheme } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { FlexGrowCol, FlexGrowRow } from 'sdk-xy-react'

import Account from './Account/Account'
import Addresses from './Addresses'
import LifeHashAppBar from './AppBar'
import Brochure from './Brochure/Brochure'
import Footer from './Components/Footer'
import InvertableThemeProvider from './Components/InvertableThemeProvider'
import Connection from './Connections/Connection'
import ApiLoader from './ContextLoaders/ApiLoader'
import AppSettingsLoader from './ContextLoaders/AppSettings'
import FirebaseLoader from './ContextLoaders/FirebaseContext'
import AppSettingsContext from './Contexts/AppSettingsContext'
import Devices from './Devices'
import Emails from './Emails'
import OAuth from './OAuth/OAuth'
import People from './People'
import { Profile } from './Profile'
import Telephones from './Telephones'
import themeOptions from './theme/themeOptions'

const AppBody: React.FC = () => {
  const theme = useTheme()
  return (
    <FirebaseLoader>
      <ApiLoader>
        <Router>
          <FlexGrowCol
            width="100vw"
            minHeight="100vh"
            justifyContent="flex-start"
            alignContent="stretch"
            bgcolor={theme.palette.background.default}
            color={theme.palette.text.primary}
          >
            <LifeHashAppBar />
            <FlexGrowRow>
              <Switch>
                <Route component={Account} path="/account" />
                <Route component={Addresses} path="/addresses" />
                <Route component={Devices} path="/devices" />
                <Route component={Emails} path="/emails" />
                <Route component={Telephones} path="/telephones" />
                <Route component={People} path="/people" />
                <Route component={OAuth} path="/oauth" />
                <Route component={Connection} path="/connections" />
                <Route component={Profile} exact path="/" />
                <Route component={Brochure} />
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </FlexGrowRow>
            <Footer />
          </FlexGrowCol>
        </Router>
      </ApiLoader>
    </FirebaseLoader>
  )
}

const AppThemeBody: React.FC = () => {
  const { darkMode, denseMode } = React.useContext(AppSettingsContext)
  return (
    <InvertableThemeProvider dark={darkMode} dense={denseMode} options={themeOptions}>
      <AppBody />
    </InvertableThemeProvider>
  )
}

const App: React.FC = () => {
  return (
    <InvertableThemeProvider options={themeOptions}>
      <Helmet defaultTitle="Lifehash.org" titleTemplate="%s | Lifehash.org" />
      <AppSettingsLoader>
        <AppThemeBody />
      </AppSettingsLoader>
    </InvertableThemeProvider>
  )
}

export default App
