import React from 'react'
import { LifehashApiConnectionScope } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import ConnectionContext from '../Contexts/ConnectionContext'

const useScopes = () => {
  const [scopes, setScopes] = React.useState<LifehashApiConnectionScope[]>()

  const { connection, error } = React.useContext(ConnectionContext)

  useAsyncEffect(
    async (isMounted) => {
      setScopes(undefined)
      if (connection) {
        const scopes = await connection.scopes().get()
        if (isMounted()) {
          setScopes(scopes)
        }
      }
    },
    [connection]
  )

  return { error, scopes }
}

export default useScopes
