import { IconButton } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { AiOutlineCloseCircle, AiOutlineFilter, AiOutlineReload } from 'react-icons/ai'
import { RiBracesLine } from 'react-icons/ri'
import { assertEx, FlexGrowCol, FlexGrowPaper, FlexRow } from 'sdk-xy-react'

import { ScoreViewer } from '../../Components'
import ProviderIcon from '../../Components/ProviderIcon'
import ApiContext from '../../Contexts/ApiContext'
import ConnectionContext from '../../Contexts/ConnectionContext'
import PersonContext from '../../Contexts/PersonContext'
import { DetailsJsonViewer, InfoViewer, ScopesViewer } from './Viewer'

const ConnectionDetails: React.FC = () => {
  const connection = useContext(ConnectionContext)
  const { removeConnection } = useContext(ApiContext)
  const { person, refresh, error } = useContext(PersonContext)
  const [jsonOpen, setJsonOpen] = useState(false)
  const [scopesOpen, setScopesOpen] = useState(false)

  const id = assertEx(connection.id, 'Missing Connection Id')

  const onDisconnect = async () => {
    await removeConnection?.(id.provider, id.provider_unique_id)
  }

  const onRefresh = () => {
    setJsonOpen(false)
    refresh?.()
  }

  const onJson = () => {
    setJsonOpen(!jsonOpen)
  }

  const onScopes = () => {
    setScopesOpen(!scopesOpen)
  }

  return (
    <FlexGrowPaper padding={1} variant="outlined">
      <FlexGrowCol alignItems="stretch">
        <FlexRow>
          <ProviderIcon provider={id.provider} marginX={2} />
          <InfoViewer error={error} person={person} />
          <FlexRow marginX={0.5}>
            <IconButton onClick={onScopes}>
              <AiOutlineFilter size={24} />
            </IconButton>
          </FlexRow>
          <FlexRow marginX={0.5}>
            <IconButton onClick={onJson}>
              <RiBracesLine size={24} />
            </IconButton>
          </FlexRow>
          <FlexRow marginX={0.5}>
            <IconButton onClick={onDisconnect}>
              <AiOutlineCloseCircle size={24} />
            </IconButton>
          </FlexRow>
          <FlexRow marginX={0.5}>
            <IconButton onClick={onRefresh}>
              <AiOutlineReload size={24} />
            </IconButton>
          </FlexRow>
          <ScoreViewer value={person?.ex_score} margin={0.5} />
        </FlexRow>
        <DetailsJsonViewer json={person} open={jsonOpen} margin={1} />
        <ScopesViewer open={scopesOpen} margin={1} />
      </FlexGrowCol>
    </FlexGrowPaper>
  )
}

export default ConnectionDetails
