import React from 'react'
import { EmailEx, LifehashError } from 'sdk-lifehash-js'

interface EmailsContextProps {
  emails?: EmailEx[]
  error?: LifehashError
  refresh?: () => void
}

const EmailsContext = React.createContext<EmailsContextProps>({})
export default EmailsContext
