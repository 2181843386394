import { Container } from '@material-ui/core'
import React from 'react'

import BasePage from '../BasePage'
import TelephonesLoader from '../ContextLoaders/TelephonesLoader'
import TelephoneList from './TelephoneList'

const ListPage: React.FC = () => {
  return (
    <BasePage requireAuth={true}>
      <Container
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <TelephonesLoader>
          <TelephoneList />
        </TelephonesLoader>
      </Container>
    </BasePage>
  )
}

export default ListPage
