import React from 'react'
import { LifehashError } from 'sdk-lifehash-js'
import useAsyncEffect from 'use-async-effect'

import useConnections from './useConnections'

const useScore = () => {
  const [score, setScore] = React.useState<number>()
  const [error, setError] = React.useState<LifehashError>()

  const connectionsHook = useConnections()
  const connections = connectionsHook.connections
  const connectionsError = connectionsHook.error

  React.useEffect(() => {
    setError(undefined)
    if (connectionsError) {
      setError(connectionsError)
      setScore(undefined)
    }
  }, [connectionsError])

  useAsyncEffect(
    async (isMounted) => {
      setScore(undefined)
      setError(undefined)
      try {
        if (connections) {
          let score: number | undefined
          for (const connection of connections ?? []) {
            score = (score ?? 0) + ((await connection.getScore()) ?? 0)
          }
          if (isMounted()) {
            setScore(score)
          }
        }
      } catch (ex) {
        if (isMounted()) {
          setError(ex)
        }
      }
    },
    [connections]
  )

  return { error, score }
}

export default useScore
