import { BoxProps } from '@material-ui/core'
import React from 'react'
import { FlexGrowRow } from 'sdk-xy-react'

import useScopes from '../../../Hooks/useScopes'
import ScopeViewer from './Scope'

interface Props extends BoxProps {
  open?: boolean
}

const ScopesViewer: React.FC<Props> = (props) => {
  const { open, ...boxProps } = props
  const { scopes } = useScopes()
  return open ? (
    <FlexGrowRow {...boxProps} justifyContent="flex-start">
      {scopes ? scopes?.map((scope) => <ScopeViewer name={scope.name} />) : null}
    </FlexGrowRow>
  ) : null
}

export default ScopesViewer
