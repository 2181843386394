import { Box, Button, TextField, Typography } from '@material-ui/core'
import React, { ChangeEvent, useContext, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import BasePage from '../BasePage'
import { CoverProgress } from '../Components'
import FirebaseContext from '../Contexts/FirebaseContext'

const SigninPage: React.FC = () => {
  const { firebaseWrapper, signedIn } = useContext(FirebaseContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const [busy, setBusy] = useState(false)

  const onSignIn = async () => {
    try {
      setBusy(true)
      await firebaseWrapper?.getAuth()?.signInWithEmailAndPassword(email, password)
      history.push('/')
    } catch (ex) {
      alert(ex.message ?? ex)
      setBusy(false)
    }
  }
  const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value)
  }
  const onPasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  if (signedIn === undefined || busy) {
    return <CoverProgress />
  }

  return (
    <BasePage>
      <Box alignItems="center" display="flex" flexDirection="column" flexGrow={1} justifyContent="center" width="100vw">
        <Typography variant="h1">Sign into Lifehash</Typography>
        <TextField id="email" label="Email" onChange={onEmailChange} type="text" value={email} variant="outlined" />
        <TextField
          id="password"
          label="Password"
          onChange={onPasswordChange}
          type="password"
          value={password}
          variant="outlined"
        />
        <Box display="flex">
          <Box margin={1}>
            <Button component={RouterLink} to="/account/create" variant="outlined">
              Create Account
            </Button>
          </Box>
          <Box margin={1}>
            <Button onClick={onSignIn} variant="contained">
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </BasePage>
  )
}

export default SigninPage
