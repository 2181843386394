import React from 'react'
import { LifehashConnectionApiWrapper, LifehashConnectionCompoundId, LifehashError } from 'sdk-lifehash-js'

interface ConnectionContextProps {
  id?: LifehashConnectionCompoundId
  connection?: LifehashConnectionApiWrapper
  error?: LifehashError
}

const ConnectionContext = React.createContext<ConnectionContextProps>({})
export default ConnectionContext
